<script>
import AuthTemplate from '@/components/authentication/AuthTemplate'
import InputField from '@/components/general/InputField'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'InviteLoginPage',
  components: {
    AuthTemplate,
    InputField
  },
  data () {
    return {
      attemptingLogin: false,
      subdomain: null,
      companyName: null,
      avatar: null,
      inviteId: null,
      pwOrEmailInvalid: false,
      formData: {
        email: null,
        password: null
      }
    }
  },
  computed: {
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces
    }
  },
  watch: {
    'formData.password' (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.pwOrEmailInvalid = false
      }
    }
  },
  created () {
    this.formData.email = this.$route.query.email
    this.subdomain = this.$route.query.subdomain
    this.companyName = this.$route.query.companyName
    this.avatar = this.$route.query.avatar
    this.inviteId = this.$route.query.invite_id
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide')
  },
  beforeRouteLeave (to, from, next) {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'show')
    next()
  },
  validations: {
    formData: {
      password: {
        required,
        passwordOrEmailInvalid: {
          value: function () {
            return !this.pwOrEmailInvalid
          }
        }
      }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      this.attemptingLogin = true
      this.$store.dispatch('attemptPerformLogin', { email: this.formData.email, password: this.formData.password })
        .then(() => {
          this.$store.dispatch('attemptAcceptInvite', { inviteId: this.inviteId })
            .then(() => {
              this.$store.dispatch('attemptPerformLogin', { email: this.formData.email, password: this.formData.password })
                .then(({ data }) => {
                  this.setPlooralGlobalToken(data.token)
                  if (this.userWorkspaces.length) {
                    this.userWorkspaces.forEach(workspace => {
                      if (workspace.subdomain && workspace.subdomain === this.subdomain) {
                        this.$store.commit('setSelectedWorkspace', workspace)
                        window.location.href = `${this.baseUrl}/c/${this.subdomain}/tos?path=/c/${this.subdomain}&subdomain=${this.subdomain}&help=1`
                      }
                    })
                  }
                })
            })
            .catch(err => {
              if (err && err.response && err.response.data && err.response.data.error) {
                if (err.response.data.error.includes('email_already_registered')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:already_registered')
                  })
                }
                if (err.response.data.error.includes('expiredInvite')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:expired')
                  })
                }
                if (err.response.data.error.includes('invalidInvite') || err.response.data.error.includes('errorVerifyingUserInvite')) {
                  this.$store.dispatch('attemptSetFeedbackMsg', {
                    type: 'error',
                    title: this.$t('registration.form:invalid')
                  })
                }
              } else {
                this.$store.dispatch('attemptSetFeedbackMsg', {
                  type: 'error',
                  title: this.$t('registration.form:error')
                })
              }
            })
        })
        .catch(() => {
          this.pwOrEmailInvalid = true
        })
        .finally(() => {
          this.attemptingLogin = false
        })
    }
  }
}
</script>
<template>
  <auth-template color="dark-blue" :title="$t('global:welcome')" :subtitle="$t('login.page:subtitle')">
    <template v-slot:image>
      <img src="/assets/images/login-3x.png" height="100%" />
    </template>
    <template v-slot:form>
      <div class="login--container">
      <v-card class="login-form--wrapper">
      <div style="text-align: center" v-if="!!avatar">
        <v-avatar class="round" size="128">
          <v-img :src="avatar" height="128px" width="128px"></v-img>
        </v-avatar>
      </div>
      <h4>{{ $t('login.form:title') + (companyName ? ' | ' + companyName : '') }}</h4>
      <input-field
        type="email"
        autocomplete="username"
        class="mt-10"
        outlined
        disabled
        v-model="formData.email"
        :placeholder="$t('registration.form:email')"
        @keydown.enter.prevent="submit()"
      />
      <input-field
        type="password"
        autocomplete="current-password"
        outlined
        v-model="formData.password"
        :placeholder="$t('login.form:password')"
        :validation="$v.formData.password"
        :hide-validation="!$v.formData.password.$error && isMobile"
        @keydown.enter="submit()"
      />
      <router-link class="login-form-subtitle" to='/recovery'>{{$t('login.form:recover')}}</router-link>
      <v-btn class="mt-10 btn-primary" :disabled="attemptingLogin" large block @click="submit()">{{ $t(attemptingLogin ? 'global:wait' : 'login.form:submit') }}</v-btn>
      </v-card>
      </div>
    </template>
  </auth-template>
</template>
<style lang="scss">
  .login--container {
    max-width: 100%;
    width: 515px;
    .login-form--wrapper {
      padding: 45px 64px;
      border-radius: 10px;
      text-align: left;
      .registration-form-subtitle {
        @extend .subtitle--1;
        @extend .neutral-medium;
      }
    }
    @media only screen and (max-width: 768px) {
      .login-form--wrapper {
        border-radius: 10px 10px 0 0;
        padding: 36px 24px 80px;
      }
    }
  }
</style>
